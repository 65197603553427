
.box {
    position:relative;
}

.combatButton {
    background-color: darkred;
    border-radius: 15px;
    position:absolute;
    top:0;
    left:0;    
    
}
.exploreButton {
    background-color: rgb(34, 172, 53);
    border-radius: 15px;
    position:absolute;
    top:0;
    right:0;    
}

.inventoryButton{
    background-color: rgb(204, 111, 34);
    border-radius: 15px;
    position:absolute;
    bottom:0;
    left:0;    
}
.magicButton{
    background-color: rgb(34, 156, 204);
    border-radius: 15px;
    position:absolute;
    bottom:0;
    right:0;    
}


.center {

    background-color: rgb(102, 102, 102);
    border-radius: 15px;
    position:absolute;
    border: top 20px;
    right:50%;    
  }